<template>
  <div id="vehicle-ctrl" class="vehicle-ctrl" style="height:100%;">
    <vxe-modal
      v-model="ctrlVisible"
      width="70%"
      height="380px"
      :class="[cmdDisabled ? 'remote-control-warn':'']"
      :title="modalTitle"
      @close="handleCloaseCtrl"
      @hide="handleCloaseCtrl"
      show-zoom
      resize
      :lock-view="false"
      :mask="false"
      :esc-closable="true"
      :position="position"
      transfer
    >
      <template #default>
        <div style="height: 100%;">
          <a-descriptions v-if="info" bordered size="small" class="vehicle-ctrl-info">
            <a-descriptions-item label="速度">
              {{ runningSpeed }}KM/H
            </a-descriptions-item>
            <a-descriptions-item label="方向">
              {{ locationHeading }}°
            </a-descriptions-item>
            <a-descriptions-item label="电量">
              {{ currentSoc }}%
            </a-descriptions-item>
            <a-descriptions-item label="转角">
              {{ currentSteer }}°
            </a-descriptions-item>
            <a-descriptions-item label="刹车状态">
              <!-- {{ info.runningState ? info.runningState.brake : '' }} -->
              {{ cmd.brake ? '刹车中' : '未刹车' }}
            </a-descriptions-item>
            <a-descriptions-item label="作业状态">
              {{ workStatus }}
            </a-descriptions-item>
            <a-descriptions-item label="油门">
              {{ cmd.throttle ? '加速中':'' }}
            </a-descriptions-item>
            <a-descriptions-item label="刹车">
              {{ cmd.brake ? '是' : '否' }}
            </a-descriptions-item>
          </a-descriptions>
          <a-row class="control" style="margin-top:10px">
            <a-col :span="12">
              <a-row type="flex" justify="space-around">
                <a-col :span="8"></a-col>
                <a-col :span="8" style="text-align: center;">
                  <a-button :disabled="cmdDisabled" @click="forward" :type="upGearBtn ? 'danger' : ''" icon="up" class="up-btn">前进(W)</a-button>
                </a-col>
                <a-col :span="8"></a-col>
              </a-row>
              <a-row type="flex" justify="space-around">
                <a-col :span="8" style="text-align: right;">
                  <a-button :disabled="cmdDisabled" @mousedown="turnLeftClickDown" @mouseup="turnLeftClickUp" :type="leftBtn ? 'danger' : ''" icon="left" class="left-btn">左转(A)</a-button>
                </a-col>
                <a-col :span="8"></a-col>
                <a-col :span="8" style="text-align: left;">
                  <a-button :disabled="cmdDisabled" @mousedown="turnRightClickDown" @mouseup="turnRightClickUp" :type="rightBtn ? 'danger' : ''" icon="right" class="right-btn">右转(D)</a-button>
                </a-col>
              </a-row>
              <a-row type="flex" justify="space-around">
                <a-col :span="8"></a-col>
                <a-col :span="8" style="text-align: center;">
                  <a-button :disabled="cmdDisabled" @click="reversing" :type="downGearBtn ? 'danger' : ''" icon="down" class="down-btn">后退(S)</a-button>
                </a-col>
                <a-col :span="8"></a-col>
              </a-row>
            </a-col>
            <a-col :span="12">
              <a-row :gutter="[8,8]">
                <a-col :span="24" style="text-align: right;">
                  <a-radio-group button-style="solid">
                    驾驶模式：
                    <a-radio-button :disabled="cmdDisabled" value="a" @click="automode">
                      自动
                    </a-radio-button>
                    <a-radio-button :disabled="cmdDisabled" value="b" @click="unautomode">
                      手动
                    </a-radio-button>
                  </a-radio-group>
                </a-col>
              </a-row>
              <a-row :gutter="[8,8]">
                <a-col :span="12"><a-button :disabled="cmdDisabled" @click="neutral" :type="neutralPositionBtn ? 'danger' : ''" style="width:100%;">空档(N)</a-button></a-col>
                <a-col :span="12"><a-button :disabled="cmdDisabled" @click="parking" :type="parkingBtn ? 'danger' : ''" style="width:100%;">停车(P)</a-button></a-col>
              </a-row>
              <a-row :gutter="[8,8]">
                <a-col :span="12"><a-button :disabled="cmdDisabled" @mousedown="guanDown" @mouseup="guanUp" :type="throttleBtn ? 'danger' : ''" style="width:100%;">油门(J)</a-button></a-col>
                <a-col :span="12"><a-button :disabled="cmdDisabled" @mousedown="brakeDown" @mouseup="brakeUp" :type="brakeBtn ? 'danger' : ''" style="width:100%;">刹车(K)</a-button></a-col>
              </a-row>
              <a-row :gutter="[8,8]">
                <a-col :span="24"><a-button :disabled="cmdDisabled" @click="estop" :type="emergencyStop ? 'danger' : ''" style="width:100%;">紧急停车(Space)</a-button></a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import get from 'lodash/get'
// import { handleModeData } from '@/translatedata/systemData.js'
import { mapState } from 'vuex'
import { setAutomode, login, logout } from '@/api/device/carcontrol'

export default {
  name: 'MonitorVehicleCtrl',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    ctrlVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  computed: {
    ...mapState({
      websocketReady: state => state.websocketCmd.websocketCmdReady,
      ackData: state => state.websocket.ackData
    }),
    vehicleId () {
      return get(this.info, 'vehicleId', '')
    },
    modalTitle () {
      if (!this.ackData.login) {
        // 正在与车端车接中
        return this.vehicleId + '-遥控模式 主窗口' + ' 与车端连接中-遥控功能暂不可用'
      }
      const loginResult = this.ackData.login.result
      const cmdResult = this.ackData.cmd ? this.ackData.cmd.result : 0
      if (loginResult === 1 || cmdResult === 1) {
         return this.vehicleId + '-遥控模式 主窗口' + ' 与车端重新连接中-遥控功能暂不可用'
      }

     if (loginResult > 2 || cmdResult > 2) {
       const msg = this.selectDictLabel(this.vehicleRemoteCtlResultOptions, loginResult > 2 ? loginResult : cmdResult)
       return this.vehicleId + '-遥控模式 主窗口' + ' 车端当前不可被遥控，请稍后重新打开遥控功能，错误信息：' + msg
     }
     return this.vehicleId + '-遥控模式 主窗口'
    },
    // 速度
    runningSpeed() {
      const speed = Number(get(this.info, 'runningState.speed', 0))
      return speed ? speed.toFixed(2) : speed
    },
    // 方向
    locationHeading() {
      const headingVal = Number(get(this.info, 'location.heading', 0))
      return headingVal ? headingVal.toFixed(2) : headingVal
    },
    // 当前电量
    currentSoc() {
      const soc = Number(get(this.info, 'runningState.soc', 0))
      return soc ? soc.toFixed(2) : soc
    },
    // 车辆转角
    currentSteer() {
      const steer = Number(get(this.info, 'runningState.steer', 0))
      const steerStr = steer ? steer.toFixed(2) : steer
      if (steer > 0) {
        return '右' + steerStr
      } else if (steer < 0) {
        return '左' + steerStr
      } else {
        return steer
      }
    },
    // 作业状态
    workStatus() {
      // console.log('getWorkStatus')
      if (!this.taskStatusOptions) {
        return ''
      }
      const workStatus = this.info.businessState ? this.info.businessState.state : -1
      return this.selectDictLabel(this.taskStatusOptions, workStatus)
    }
  },
  data () {
    return {
      emergencyStop: false,
      throttleBtn: false,
      brakeBtn: false,
      upBtn: false,
      downBtn: false,
      upGearBtn: false,
      downGearBtn: false,
      leftBtn: false,
      rightBtn: false,
      neutralPositionBtn: false,
      parkingBtn: false,
      cmd: {
        left: false,
        right: false,
        brake: false,
        throttle: false,
        slambrake: false,
        drive: false,
        neutral: false,
        reverse: false,
        parking: false
      },
      // 发送车端指令时间
      timer: 100,
      // timer: 3000,
      timerRun: null,
      taskStatusOptions: [],
      // 遥控车辆返回状态结果
      vehicleRemoteCtlResultOptions: [],
      cmdDisabled: true
    }
  },
  created () {
    //  登陆车辆遥控
    // this.loginVehicle()
    // console.log('created vehicleId:', this.vehicleId)
    this.getDicts('iot_vehicle_remoteCtlResult').then((response) => {
        this.vehicleRemoteCtlResultOptions = response.data
      })
    this.initTimer()
  },
  mounted () {
    window.addEventListener('keydown', this.handleKeydown)
    window.addEventListener('keyup', this.handleKeyup)
    this.getDicts('iot_task_state').then((response) => {
      this.taskStatusOptions = response.data
    })
  },
  beforeDestroy () {
    // 重置遥控ack数据
    console.log('beforeDestroy')
    this.$store.dispatch('resetAck')
    // 退出定期执行控制
		clearInterval(this.timerRun)
    //  登出车辆遥控
    this.logoutVehicle()
    // this.$store.dispatch('stopWebSocket')
  },
  destroyed () {
    // 移除监控键盘时间
    window.removeEventListener('keyup', this.handleKeyup)
    window.removeEventListener('keydown', this.handleKeydown)
  },
  watch: {
     'info.vehicleId' (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleCloaseCtrl()
      }
    },
    ctrlVisible(newVal, oldVal) {
      console.log('ctrl ctrlVisible oldVal:', oldVal)
      console.log('ctrl ctrlVisible newVal:', newVal)
    },
    ackData(newVal, oldVal) {
      console.log('遥控收到数据', newVal)
      if (newVal && newVal.login) {
            const loginResult = newVal.login.result
            const cmdResult = newVal.cmd ? newVal.cmd.result : 0
            if ((loginResult === 0 || loginResult === 2) && (cmdResult === 0 || cmdResult === 2)) {
              // 已登录与遥控指令正常
              this.cmdDisabled = false
            } else {
              this.cmdDisabled = true
            }
      } else {
        this.cmdDisabled = true
      }
    }
  },
  methods: {
    initTimer() {
      this.timerRun = setInterval(() => {
        this.sendVehicleCMD()
      }, this.timer)
    },
    // 发送遥控指令
    async sendVehicleCMD() {
      console.log('sendVehicleCMD')
      if (!this.websocketReady) {
        await this.$store.dispatch('websocketCmdConnect')
      }
      if (this.vehicleId && this.ackData && this.ackData.login) {
        const loginResult = this.ackData.login.result
        const cmdResult = this.ackData.cmd ? this.ackData.cmd.result : 0
        if ((loginResult === 0 || loginResult === 2) && (cmdResult === 0 || cmdResult === 2)) {
           // 已登录与遥控指令正常
           if (this.loginStart > 0) {
              console.log('恢复正常遥控', (new Date().getTime() - this.loginStart) / 100)
              this.loginStart = 0
           }
            this.$store
          .dispatch('websocketCmdSend', { id: this.vehicleId, cmd: this.cmd })
          .then(() => {})
          .finally(() => {
          })
        } else {
            this.timerRun && clearInterval(this.timerRun)
            if (loginResult === 1 || cmdResult === 1) {
              // 重新登录车辆
              this.loginStart = new Date().getTime()
              this.loginVehicle()
            }
        }
      }
    },
    loginVehicle() {
      console.log('登陆车辆遥控')
      login({
        vehicleId: this.vehicleId,
        data: {}
      }).then(response => {
        console.log('run 登陆车辆遥控 end')
        this.initTimer()
      })
    },
    logoutVehicle() {
      console.log('登出车辆遥控')
      logout({
        vehicleId: this.vehicleId,
        data: {}
      }).then(response => {
        console.log('run 登出车辆遥控 end')
        this.timerRun && clearInterval(this.timerRun)
      })
    },
    automode() {
      console.log('自动')
      setAutomode({
        vehicleId: this.vehicleId,
        data: {
          'automode': true
        }
      }).then(response => {
        console.log('run auto mode end')
      })
    },
    unautomode() {
      console.log('手动')
      setAutomode({
        vehicleId: this.vehicleId,
        data: {
          'automode': false
        }
      }).then(response => {
        console.log('run auto mode end')
      })
    },
    // keyup键盘事件
    handleKeyup(event) {
      // eslint-disable-next-line no-caller
      const e = event || window.event || arguments.callee.caller.arguments[0]
      if (!e) return
      const { key, keyCode } = e
      console.log(`key=${key}, keyCode=${keyCode}`)
      if (keyCode === 32 || keyCode === 74 || keyCode === 75) {
        // if (keyCode === 32) { // 紧急停车
        //   this.emergencyStop = true
        //   this.throttleBtn = false
        //   this.brakeBtn = false
        // }
        if (keyCode === 74) { // 加油
          this.throttleBtn = false
          this.cmd.throttle = false
        }
        if (keyCode === 75) { // 刹车
          this.brakeBtn = false
          this.cmd.brake = false
        }
      }
      if (keyCode === 65 || keyCode === 68) {
        if (keyCode === 65) { // 左转
          this.leftBtn = false
          this.cmd.left = false
        }
        if (keyCode === 68) { // 右转
          this.rightBtn = false
          this.cmd.right = false
        }
      }
    },
    // keydown键盘事件
    handleKeydown(event) {
      // eslint-disable-next-line no-caller
      const e = event || window.event || arguments.callee.caller.arguments[0]
      if (!e) return
      const { key, keyCode } = e
      console.log(`key=${key}, keyCode=${keyCode}`)
      if (keyCode === 32 || keyCode === 74 || keyCode === 75) {
        if (keyCode === 32) { // 紧急停车
          this.emergencyStop = true
          this.throttleBtn = false
          this.brakeBtn = false

          // 紧急停车把档位跳到停车档
          this.parkingBtn = true
          this.upGearBtn = false
          this.downGearBtn = false
          this.neutralPositionBtn = false

          this.cmd.slambrake = true
          this.cmd.parking = false
          this.cmd.drive = false
          this.cmd.reverse = false
          this.cmd.neutral = false
          this.cmd.brake = false
          this.cmd.throttle = false
        }
        if (keyCode === 74) { // 加油
          this.throttleBtn = true
          this.emergencyStop = false
          this.brakeBtn = false

          this.cmd.brake = false
          this.cmd.throttle = true
        }
        if (keyCode === 75) { // 刹车
          this.brakeBtn = true
          this.emergencyStop = false
          this.throttleBtn = false

          this.cmd.throttle = false
          this.cmd.brake = true
        }
      }
      if (keyCode === 65 || keyCode === 68) {
        if (keyCode === 65) { // 左转
          this.leftBtn = true
          this.rightBtn = false
          this.emergencyStop = false

          this.cmd.left = true
          this.cmd.right = false
        }
        if (keyCode === 68) { // 右转
          this.rightBtn = true
          this.leftBtn = false
          this.emergencyStop = false

          this.cmd.right = true
          this.cmd.left = false
        }
      }
      if (keyCode === 87 || keyCode === 83 || keyCode === 78 || keyCode === 80) {
        if (keyCode === 87) { // 前进档
          this.upGearBtn = true
          this.downGearBtn = false
          this.neutralPositionBtn = false
          this.parkingBtn = false
          this.emergencyStop = false

          this.cmd.drive = true
          this.cmd.parking = false
          this.cmd.neutral = false
          this.cmd.reverse = false
          this.cmd.slambrake = false
        }
        if (keyCode === 83) { // 倒退档
          this.downGearBtn = true
          this.upGearBtn = false
          this.neutralPositionBtn = false
          this.parkingBtn = false
          this.emergencyStop = false

          this.cmd.drive = false
          this.cmd.parking = false
          this.cmd.neutral = false
          this.cmd.reverse = true
          this.cmd.slambrake = false
        }
        if (keyCode === 78) { // 空档
          this.neutralPositionBtn = true
          this.upGearBtn = false
          this.downGearBtn = false
          this.parkingBtn = false
          this.emergencyStop = false

          this.cmd.drive = false
          this.cmd.parking = false
          this.cmd.neutral = true
          this.cmd.reverse = false
          this.cmd.slambrake = false
        }
        if (keyCode === 80) { // 停车
          this.parkingBtn = true
          this.upGearBtn = false
          this.downGearBtn = false
          this.neutralPositionBtn = false
          this.emergencyStop = false

          this.cmd.drive = false
          this.cmd.parking = true
          this.cmd.neutral = false
          this.cmd.reverse = false
          this.cmd.slambrake = false
        }
      }
    },
    turnLeftClickDown() {
      this.leftBtn = true
      this.rightBtn = false
      this.emergencyStop = false

      this.cmd.left = true
      this.cmd.right = false
    },
    turnLeftClickUp() {
      this.leftBtn = false
      // 退出增加向左
      this.cmd.left = false
    },
    turnRightClickDown() {
      this.rightBtn = true
      this.leftBtn = false
      this.emergencyStop = false

      this.cmd.right = true
      this.cmd.left = false
    },
    turnRightClickUp() {
      this.rightBtn = false
      // 退出增加向右
      this.cmd.right = false
    },
    neutral() { // 空档
      this.neutralPositionBtn = true
      this.upGearBtn = false
      this.downGearBtn = false
      this.parkingBtn = false
      this.emergencyStop = false

      this.cmd.drive = false
      this.cmd.parking = false
      this.cmd.neutral = true
      this.cmd.reverse = false
      this.cmd.slambrake = false
    },
    parking() { // 停车
      this.parkingBtn = true
      this.upGearBtn = false
      this.downGearBtn = false
      this.neutralPositionBtn = false
      this.emergencyStop = false

      this.cmd.drive = false
      this.cmd.parking = true
      this.cmd.neutral = false
      this.cmd.reverse = false
      this.cmd.slambrake = false
    },
    reversing() { // 倒车
      this.downGearBtn = true
      this.upGearBtn = false
      this.neutralPositionBtn = false
      this.parkingBtn = false
      this.emergencyStop = false

      this.cmd.drive = false
      this.cmd.parking = false
      this.cmd.neutral = false
      this.cmd.reverse = true
      this.cmd.slambrake = false
    },
    forward() { // 前进档
      this.upGearBtn = true
      this.downGearBtn = false
      this.neutralPositionBtn = false
      this.parkingBtn = false
      this.emergencyStop = false

      this.cmd.drive = true
      this.cmd.parking = false
      this.cmd.neutral = false
      this.cmd.reverse = false
      this.cmd.slambrake = false
    },
    estop() {
      this.emergencyStop = true
      this.throttleBtn = false
      this.brakeBtn = false

      // 紧急停车把档位跳到停车档
      this.parkingBtn = true
      this.upGearBtn = false
      this.downGearBtn = false
      this.neutralPositionBtn = false

      this.cmd.slambrake = true
      this.cmd.parking = true
      this.cmd.drive = false
      this.cmd.reverse = false
      this.cmd.neutral = false
      this.cmd.brake = false
      this.cmd.throttle = false
    },
    guanDown() {
      this.throttleBtn = true
      this.emergencyStop = false
      this.brakeBtn = false

      this.cmd.brake = false
      this.cmd.throttle = true
    },
    guanUp() {
      // this.throttleBtn = false
      // 退出增加油门
      // clearInterval(this.timerRunGuan)
      // this.timerRunGuan = null
    },
    brakeDown() {
      this.brakeBtn = true
      this.emergencyStop = false
      this.throttleBtn = false

      this.cmd.throttle = false
      this.cmd.brake = true
    },
    brakeUp() {
      this.brakeBtn = false
      this.cmd.brake = false
    },
    handleCloaseCtrl () {
      this.ctrlVisible = false
      // 移除监控键盘时间
      window.removeEventListener('keyup', this.handleKeyup)
      window.removeEventListener('keydown', this.handleKeydown)
      this.$emit('closeModalCtrl')
    }
  }
}
</script>

<style lang="less">
.vehicle-ctrl {
  .ant-descriptions-bordered .ant-descriptions-view {
    border: 1px solid rgb(1, 58, 7);
  }
  .vehicle-ctrl-info {
    height: 127px;
  }
  .control {
    border: 1px solid rgb(1, 58, 7);
    border-radius: 12px;
    margin-top: 10px;
    padding: 8px;
    height: 174px;
    .up-btn {
      height: auto;
      padding: 8px 2px;
      span {
        width: 100%;
        position: relative;
        float: left;
        margin-left: 0px;
      }
    }
    .down-btn {
      height: auto;
      padding: 8px 7px 25px 7px;
      position:relative;
      i {
        position: absolute;
        bottom: 5px;
        left: 30px;
      }
      span {
        margin: auto 5px;
      }
    }
    .right-btn {
      height: auto;
      padding: 6px 20px 6px 6px;
      position:relative;
      i {
        position: absolute;
        right: 12px;
        margin-top: 3px;
      }
      span {
        margin: auto 12px;
      }
    }
  }
}

.remote-control-warn .vxe-modal--title{
 color: red;
}

</style>
