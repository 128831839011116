var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vehicle-ctrl",
      staticStyle: { height: "100%" },
      attrs: { id: "vehicle-ctrl" },
    },
    [
      _c("vxe-modal", {
        class: [_vm.cmdDisabled ? "remote-control-warn" : ""],
        attrs: {
          width: "70%",
          height: "380px",
          title: _vm.modalTitle,
          "show-zoom": "",
          resize: "",
          "lock-view": false,
          mask: false,
          "esc-closable": true,
          position: _vm.position,
          transfer: "",
        },
        on: { close: _vm.handleCloaseCtrl, hide: _vm.handleCloaseCtrl },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticStyle: { height: "100%" } },
                  [
                    _vm.info
                      ? _c(
                          "a-descriptions",
                          {
                            staticClass: "vehicle-ctrl-info",
                            attrs: { bordered: "", size: "small" },
                          },
                          [
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: "速度" } },
                              [_vm._v(" " + _vm._s(_vm.runningSpeed) + "KM/H ")]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: "方向" } },
                              [_vm._v(" " + _vm._s(_vm.locationHeading) + "° ")]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: "电量" } },
                              [_vm._v(" " + _vm._s(_vm.currentSoc) + "% ")]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: "转角" } },
                              [_vm._v(" " + _vm._s(_vm.currentSteer) + "° ")]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: "刹车状态" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.cmd.brake ? "刹车中" : "未刹车"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: "作业状态" } },
                              [_vm._v(" " + _vm._s(_vm.workStatus) + " ")]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: "油门" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.cmd.throttle ? "加速中" : "") +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: "刹车" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.cmd.brake ? "是" : "否") +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-row",
                      {
                        staticClass: "control",
                        staticStyle: { "margin-top": "10px" },
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-row",
                              {
                                attrs: {
                                  type: "flex",
                                  justify: "space-around",
                                },
                              },
                              [
                                _c("a-col", { attrs: { span: 8 } }),
                                _c(
                                  "a-col",
                                  {
                                    staticStyle: { "text-align": "center" },
                                    attrs: { span: 8 },
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "up-btn",
                                        attrs: {
                                          disabled: _vm.cmdDisabled,
                                          type: _vm.upGearBtn ? "danger" : "",
                                          icon: "up",
                                        },
                                        on: { click: _vm.forward },
                                      },
                                      [_vm._v("前进(W)")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("a-col", { attrs: { span: 8 } }),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              {
                                attrs: {
                                  type: "flex",
                                  justify: "space-around",
                                },
                              },
                              [
                                _c(
                                  "a-col",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    attrs: { span: 8 },
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "left-btn",
                                        attrs: {
                                          disabled: _vm.cmdDisabled,
                                          type: _vm.leftBtn ? "danger" : "",
                                          icon: "left",
                                        },
                                        on: {
                                          mousedown: _vm.turnLeftClickDown,
                                          mouseup: _vm.turnLeftClickUp,
                                        },
                                      },
                                      [_vm._v("左转(A)")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("a-col", { attrs: { span: 8 } }),
                                _c(
                                  "a-col",
                                  {
                                    staticStyle: { "text-align": "left" },
                                    attrs: { span: 8 },
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "right-btn",
                                        attrs: {
                                          disabled: _vm.cmdDisabled,
                                          type: _vm.rightBtn ? "danger" : "",
                                          icon: "right",
                                        },
                                        on: {
                                          mousedown: _vm.turnRightClickDown,
                                          mouseup: _vm.turnRightClickUp,
                                        },
                                      },
                                      [_vm._v("右转(D)")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              {
                                attrs: {
                                  type: "flex",
                                  justify: "space-around",
                                },
                              },
                              [
                                _c("a-col", { attrs: { span: 8 } }),
                                _c(
                                  "a-col",
                                  {
                                    staticStyle: { "text-align": "center" },
                                    attrs: { span: 8 },
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "down-btn",
                                        attrs: {
                                          disabled: _vm.cmdDisabled,
                                          type: _vm.downGearBtn ? "danger" : "",
                                          icon: "down",
                                        },
                                        on: { click: _vm.reversing },
                                      },
                                      [_vm._v("后退(S)")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("a-col", { attrs: { span: 8 } }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-row",
                              { attrs: { gutter: [8, 8] } },
                              [
                                _c(
                                  "a-col",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    attrs: { span: 24 },
                                  },
                                  [
                                    _c(
                                      "a-radio-group",
                                      { attrs: { "button-style": "solid" } },
                                      [
                                        _vm._v(" 驾驶模式： "),
                                        _c(
                                          "a-radio-button",
                                          {
                                            attrs: {
                                              disabled: _vm.cmdDisabled,
                                              value: "a",
                                            },
                                            on: { click: _vm.automode },
                                          },
                                          [_vm._v(" 自动 ")]
                                        ),
                                        _c(
                                          "a-radio-button",
                                          {
                                            attrs: {
                                              disabled: _vm.cmdDisabled,
                                              value: "b",
                                            },
                                            on: { click: _vm.unautomode },
                                          },
                                          [_vm._v(" 手动 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              { attrs: { gutter: [8, 8] } },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: _vm.cmdDisabled,
                                          type: _vm.neutralPositionBtn
                                            ? "danger"
                                            : "",
                                        },
                                        on: { click: _vm.neutral },
                                      },
                                      [_vm._v("空档(N)")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: _vm.cmdDisabled,
                                          type: _vm.parkingBtn ? "danger" : "",
                                        },
                                        on: { click: _vm.parking },
                                      },
                                      [_vm._v("停车(P)")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              { attrs: { gutter: [8, 8] } },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: _vm.cmdDisabled,
                                          type: _vm.throttleBtn ? "danger" : "",
                                        },
                                        on: {
                                          mousedown: _vm.guanDown,
                                          mouseup: _vm.guanUp,
                                        },
                                      },
                                      [_vm._v("油门(J)")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: _vm.cmdDisabled,
                                          type: _vm.brakeBtn ? "danger" : "",
                                        },
                                        on: {
                                          mousedown: _vm.brakeDown,
                                          mouseup: _vm.brakeUp,
                                        },
                                      },
                                      [_vm._v("刹车(K)")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              { attrs: { gutter: [8, 8] } },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: _vm.cmdDisabled,
                                          type: _vm.emergencyStop
                                            ? "danger"
                                            : "",
                                        },
                                        on: { click: _vm.estop },
                                      },
                                      [_vm._v("紧急停车(Space)")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.ctrlVisible,
          callback: function ($$v) {
            _vm.ctrlVisible = $$v
          },
          expression: "ctrlVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }